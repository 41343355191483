import { useTranslation } from '@pancakeswap/localization'
import { useToast } from '@pancakeswap/uikit'
import { ToastDescriptionWithTx } from 'components/Toast'
import { useCallback, useState } from 'react'
import { WaitForTransactionResult, SendTransactionResult } from 'wagmi/actions'
import { isUserRejected, logError } from 'utils/sentry'
import { useTransactionAdder } from 'state/transactions/hooks'
import { BaseError, Hash, UnknownRpcError } from 'viem'
import { usePublicNodeWaitForTransaction } from './usePublicNodeWaitForTransaction'

export type CatchTxErrorReturn = {
  fetchWithCatchTxError: (fn: () => Promise<SendTransactionResult | Hash>, txSummary?: any) => Promise<WaitForTransactionResult>
  fetchTxResponse: (fn: () => Promise<SendTransactionResult | Hash>, txSummary?: any) => Promise<SendTransactionResult>
  loading: boolean
  txResponseLoading: boolean
}

/// only show corrected parsed viem error
export function parseError<TError>(err: TError): BaseError | null {
  if (err instanceof BaseError) {
    return err
  }
  if (typeof err === 'string') {
    return new UnknownRpcError(new Error(err))
  }
  if (err instanceof Error) {
    return new UnknownRpcError(err)
  }
  return null
}

const typeToLabel = (str, lower = false) => {
  return (lower ? str.toLowerCase() : str).replaceAll('-', ' ').replace(/(?:^|\s|["'([{])+\S/g, match => match.toUpperCase());
}

const notPreview = process.env.NEXT_PUBLIC_VERCEL_ENV !== 'preview'

export default function useCatchTxError(): CatchTxErrorReturn {
  const { t } = useTranslation()
  const { toastError, toastSuccess, toastWarning } = useToast()
  const [loading, setLoading] = useState(false)
  const { waitForTransaction } = usePublicNodeWaitForTransaction()
  const [txResponseLoading, setTxResponseLoading] = useState(false)
  const addTransaction = useTransactionAdder()
  
  const handleNormalError = useCallback(
    (error, action = undefined) => {      
      logError(error)
      const err = parseError(error)
      const actionTitle = action ?? "Transaction"
      if (err) {
        toastError(
          t('Error'),
          t('%actionTitle% failed with error: %reason%', {
            actionTitle,
            reason: notPreview ? error.shortMessage || error.message : error.message,
          }),
        )
      } else {
        toastError(t('Error'), t('Please try again. Confirm the transaction and make sure you are paying enough gas!'))
      }
    },
    [t, toastError],
  )

  const handleTxError = useCallback(
    (error, hash, action = undefined) => {
      logError(error)
      const err = parseError(error)
      const actionTitle = action ?? "Transaction"
      toastError(
        t('Failed'),
        <ToastDescriptionWithTx txHash={hash}>
          {err
            ? t('%actionTitle% failed with error: %reason%', {
                actionTitle,
                reason: notPreview ? err.shortMessage || err.message : err.message,
              })
            : t('%actionTitle% failed. For detailed error message:', { actionTitle })}
        </ToastDescriptionWithTx>,
      )
    },
    [t, toastError],
  )

  const handleRejectError = useCallback(
    (action = undefined) => {
      const actionTitle = action ?? "Transaction"
      toastWarning(actionTitle, t("User rejected the request"))
    },
    [t, toastWarning]
  )

  const fetchWithCatchTxError = useCallback(
    async (callTx: () => Promise<SendTransactionResult | Hash>, txSummary?: any): Promise<WaitForTransactionResult | null> => {
      let tx: SendTransactionResult | Hash = null
      const action = typeToLabel(txSummary?.type ?? '')
      try {
        setLoading(true)

        /**
         * https://github.com/vercel/swr/pull/1450
         *
         * wait for useSWRMutation finished, so we could apply SWR in case manually trigger tx call
         */
        tx = await callTx()

        const hash = typeof tx === 'string' ? tx : tx.hash

        if (txSummary) {
          addTransaction({ hash }, txSummary)
        }

        // toastSuccess(`${t('Transaction Submitted')}!`, <ToastDescriptionWithTx txHash={hash} />)

        const receipt = await waitForTransaction({
          hash,
        })
        return receipt
      } catch (error: any) {
        if (!isUserRejected(error)) {
          if (!tx) {
            handleNormalError(error, action)
          } else {
            handleTxError(error, typeof tx === 'string' ? tx : tx.hash, action)
          }
        } else {
          handleRejectError(action)
        }
      } finally {
        setLoading(false)
      }

      return null
    },
    [waitForTransaction, handleNormalError, handleTxError, handleRejectError, addTransaction],
  )

  const fetchTxResponse = useCallback(
    async (callTx: () => Promise<SendTransactionResult | Hash>, txSummary?: any): Promise<SendTransactionResult> => {
      let tx: SendTransactionResult | Hash = null
      const action = typeToLabel(txSummary?.type ?? '')

      try {
        setTxResponseLoading(true)

        /**
         * https://github.com/vercel/swr/pull/1450
         *
         * wait for useSWRMutation finished, so we could apply SWR in case manually trigger tx call
         */
        tx = await callTx()

        const hash = typeof tx === 'string' ? tx : tx.hash

        if (txSummary) {
          addTransaction({ hash }, txSummary)
        }

        // toastSuccess(`${t('Transaction Submitted')}!`, <ToastDescriptionWithTx txHash={hash} />)

        return { hash }
      } catch (error: any) {
        if (!isUserRejected(error)) {
          if (!tx) {
            handleNormalError(error, action)
          } else {
            handleTxError(error, typeof tx === 'string' ? tx : tx.hash, action)
          }
        } else {
          handleRejectError(action)
        }
      } finally {
        setTxResponseLoading(false)
      }

      return null
    },
    [handleNormalError, handleTxError, handleRejectError, addTransaction],
  )

  return {
    fetchWithCatchTxError,
    fetchTxResponse,
    loading,
    txResponseLoading,
  }
}
